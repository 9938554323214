<template>
  <app-page name="Dashboard">
    <div class="top-content">
      <h1 class="page-title">Dashboard</h1>
      <Button
        text="Обновить"
        className="btn-secondary"
        class="_filled"
        @click="load"
      />
    </div>
    <p v-if="loading">Loading...</p>
    <div class="dashboard-item-wrapper" v-else>
      <h2>Cуммарное к-во переходов в общем по витринам</h2>
      <div class="dashboard-table-wrapper">
        <table>
          <thead>
            <th></th>
            <th v-for="c of columns" :key="c.name">
              {{ c.name }}
            </th>
          </thead>
          <tbody>
            <tr v-for="r of rows" :key="r">
              <td>{{ r.name }}</td>
              <td v-for="(o, i) of stats" :key="i">
                {{ o.name === columns[i].geo && o[r.key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="dashboard-item-wrapper" v-if="!loading">
      <h2>
        Cуммарное к-во активных вебов (у кого больше 50 переходов хотя бы по
        одному из потоков)
      </h2>
      <div
        class="dashboard-table-wrapper"
        v-if="Object.values(activity).length"
      >
        <table class="activity">
          <thead>
            <th></th>
            <th v-for="c of columns" :key="c.name">
              {{ c.name }}
            </th>
          </thead>
          <tbody>
            <tr>
              <td>К-во вебов</td>
              <td v-for="a of activity.activeData" :key="a.geo">
                {{ a.affiliate_count }}
              </td>
            </tr>
            <tr>
              <td>К-во активных потоков</td>
              <td v-for="a of activity.activeData" :key="a.geo">
                {{ a.campaigns_count }}
              </td>
            </tr>
          </tbody>
        </table>
        <p class="dashboard-table-wrapper__bottom">
          Cуммарное к-во переходов в общем по витринам (без потока):
          <span>{{
            activity && activity.inactiveData.length && activity.inactiveData[0].count
          }}</span>
        </p>
      </div>
      <p class="dashboard-table-wrapper__bottom" v-else>
        Нет активных потоков или вебов
      </p>
    </div>
  </app-page>
</template>

<script>
import AppPage from "../../components/AppPage";
import Button from "../../components/Button";

import axios from "axios";

export default {
  components: {
    AppPage,
    Button,
  },
  data() {
    return {
      loading: true,
      stats: [],
      activity: [],
      columns: [],
      rows: [
        { key: "visits", name: "Переходы" },
        { key: "users", name: "Уники" },
        { key: "views", name: "Показы" },
        { key: "clicks", name: "Клики" },
        { key: "approved_leads", name: "Апрув, шт" },
        { key: "valid_leads", name: "Лиды" },
        { key: "pending_leads", name: "Обработка, шт" },
        { key: "rejected_leads", name: "Отмена, шт" },
        { key: "trash_leads", name: "Треш, шт" },
        { key: "ctr", name: "CTR, %" },
        { key: "cr", name: "CR, %" },
        { key: "epc", name: "EPC, $" },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      (this.loading = true), (this.stats = []);
      this.activity = [];
      this.traffic = [];
      const [{ data: stats }, { data: geos }, { data: affiliates }] =
        await Promise.all([
          await axios.get("/api/dashboard/get-stats"),
          await axios.get("/api/geos"),
          await axios.get("/api/dashboard/get-activity"),
        ]);
      this.stats = stats;
      this.columns = geos;
      this.activity = affiliates;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-table-wrapper {
  overflow-x: auto;
  padding-bottom: 10px;

  table {
    margin-top: 20px;
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;

    th {
      text-align: start;
      padding: 8px;
    }

    td {
      background-color: #fff;
      font-weight: 500;
      text-align: right;
      padding: 8px;

      &:first-child {
        text-align: start;
      }
    }
  }

  &__bottom {
    margin-top: 15px;
    font-size: 14px;

    span {
      font-weight: 500;
    }
  }
}

.dashboard-item-wrapper {
  padding: 40px 0 20px;

  & + & {
    border-top: 1px solid #00004f;
    padding-top: 20px;
  }

  h2 {
    font-weight: 500;
  }
}
</style>
